<template>
  <div class="wrapper">
    <div class="template2" v-if="$route.params.type === '4'">
      <div
        class="item"
        v-for="(item,index) in resources"
        :key="index"
        @click="handleClick(item.resources_guid,item.resources_type,item.resources_name)"
      >
        <div class="pic">
          <img class="pic-img" :src="item.media_file" alt=""/>
        </div>
        <div class="info">
          <div class="desc">{{ item.resources_name }}</div>
        </div>
      </div>
    </div>
    <div class="template1" v-else>
      <div
        class="item"
        v-for="(item,index) in resources"
        :key="index"
        @click="handleClick(item.resources_guid,item.resources_type,item.resources_name)"
      >
        <div class="pic">
          <img class="pic-img" :src="item.media_file" alt=""/>
        </div>
        <div class="info">
          <div class="name">{{ item.resources_name }}</div>
          <div class="desc">{{ item.resources_desc }}</div>
          <div class="author-wrapper">
            <div class="icon"></div>
            <div class="author">{{ item.resources_author }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getAudios } from '@/api/Resources'
import { appInit } from '@/utils/init'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RecommendResourcesList',
  props: {
    resources: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    ...mapGetters([
      'agencyGuid',
      'userGuid'
    ])
  },
  methods: {
    handleClick (guid, type, name) {
      if (type === 1 || type === 5) { // 电子书
        this.$router.push(`${this.$route.path}/book-detail/${guid}/${name}`)
      }
      if (type === 2 || type === 6) { // 听书
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        getAudios({
          resources_guid: guid,
          c_user_guid: this.userGuid
        }).then(res => {
          const { code, data } = res
          if (code === 200) {
            if (!data.list.length) {
              return Toast.fail('暂无音频数据')
            }
            this.selectPlay({
              list: data.list,
              index: data.current_index
            })
          } else if (res.code === 401) {
            appInit(this.agencyGuid, this.$route.path)
          } else if (res.code === 402) {
            this.$router.push({
              name: 'Login',
              params: {
                link: this.$route.path
              }
            })
          } else {
            Toast.fail(res.msg)
          }
          Toast.clear()
        })
      }
      if (type === 3) { // 视频
        this.$router.push(`${this.$route.path}/video-detail/${guid}/${name}`)
      }
    },
    ...mapActions([
      'selectPlay'
    ])
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  display flex
  justify-content center
  width 100%

  .template1
    display flex
    flex-wrap wrap
    justify-content center
    width 100%

    .item
      display flex
      align-items center
      width 650px
      padding 0 20px
      height 270px
      margin-top 10px
      border-radius 10px
      box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.1)
      background rgba(255, 255, 255, 1)

      .pic
        width 166px
        height 228px
        border-radius 10px
        overflow hidden

        .pic-img
          width 100%
          height 100%

      .info
        display flex
        flex-wrap wrap
        align-items center
        width 464px
        padding-left 20px
        height 228px

        .name
          width 100%
          line-height normal
          font-size 26px
          color rgba(51, 51, 51, 1)
          no-wrap()

        .desc
          width 100%
          height 72px
          line-height 36px
          font-size 24px
          color rgba(153, 153, 153, 1)
          no-wrap2(2)

        .author-wrapper
          display flex
          align-items center

          .icon
            width 16px
            height 16px
            bg-image('~@/assets/img/author')
            background-size 100% 100%
            background-repeat no-repeat

          .author
            width 452px
            padding-left 10px
            line-height normal
            font-size 22px
            color rgba(153, 153, 153, 1)
            no-wrap()

  .template2
    display flex
    justify-content space-between
    align-items center
    flex-wrap wrap
    width 690px

    .item
      position relative
      width 336px
      height 240px
      margin-top 20px
      border-radius 10px
      overflow hidden

      .pic
        width 336px
        height 240px

        .pic-img
          width 100%
          height 100%

      .info
        display flex
        align-items center
        position absolute
        bottom 0
        width 336px
        height 62px
        background rgba(0, 0, 0, .5)

        .desc
          width 316px
          padding 0 10px
          line-height normal
          font-size 26px
          color rgba(255, 255, 255, 1)
          no-wrap()
</style>
