<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="container">
      <recommend-resources-header></recommend-resources-header>
      <scroll
        ref="scroll"
        class="scroll"
        :data="resources"
        :listen-scroll="true"
        @scroll="scroll"
        :pull-down-refresh="{threshold: 30, stop: 0}"
        @pullingDown="pullDownRefresh"
        :pull-up-load="true"
        @pullingUp="pullUpPush"
      >
        <div>
          <div class="pull-down-wrapper">
            <div class="pull-down-text" v-show="!isPullingDown">下拉即可刷新...</div>
            <div class="pull-down-text" v-show="isPullingDown">释放即可刷新...</div>
          </div>
          <recommend-resources-list
            :resources="resources"
          />
          <van-empty v-show="showEmpty" description="暂无内容"/>
          <div class="home-bottom" v-show="showDivider">
            <divider class="divider">已加载全部内容</divider>
          </div>
        </div>
      </scroll>
      <router-view/>
    </div>
  </transition>
</template>

<script>
import RecommendResourcesHeader from './components/Header'
import Scroll from '@/components/scroll/scroll'
import RecommendResourcesList from './components/List'
import { getRecommendResources } from '@/api/Recommends'
import { Toast, Empty, Divider } from 'vant'

export default {
  name: 'RecommendResources',
  components: {
    RecommendResourcesHeader,
    Scroll,
    RecommendResourcesList,
    VanEmpty: Empty,
    Divider
  },
  data () {
    return {
      page: 1,
      limit: 50,
      resources: [],
      isPullingDown: false,
      showEmpty: false,
      showDivider: false
    }
  },
  mounted () {
    this._getRecommendResources()
  },
  methods: {
    _getRecommendResources () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      getRecommendResources({
        recommend_guid: this.$route.params.recommend_guid,
        page: this.page,
        limit: this.limit
      }).then(res => {
        const { code, msg, data } = res
        if (code === 200) {
          this.resources = this.resources.concat(data.list)
          if (data.total <= this.resources.length) {
            this.showDivider = true
          }
          Toast.clear()
        } else {
          this.showEmpty = true
          Toast.fail(msg)
        }
      })
    },
    scroll (pos) {
      if (pos.y > 30) {
        this.isPullingDown = true
      } else if (pos.y <= 0) {
        this.isPullingDown = false
      }
    },
    pullDownRefresh () {
      this.page = 1
      this.resources.splice(0, this.resources.length)
      this._getRecommendResources()
    },
    pullUpPush () {
      if (this.showDivider) {
        return true
      }
      this.page++
      this._getRecommendResources()
    }
  }
}
</script>

<style scoped lang="stylus">
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.container
  position absolute
  z-index 100
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 80px
    left 0
    right 0
    bottom 0
    overflow hidden

    .pull-down-wrapper
      display flex
      justify-content center
      align-items center
      width 750px
      height 70px
      margin-top -70px

      .pull-down-text
        line-height normal
        font-size 22px
        color rgba(153, 153, 153, 1)

    .home-bottom
      wdith 750px
      height 70px
      padding-top 30px

      .divider
        width 300px
        height 22px
        font-size 22px
        color rgba(153, 153, 153, 1)
        margin 0 auto
        border-color rgba(153, 153, 153, 1)
</style>
